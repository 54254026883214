/* arguments inittial */
$color_body: #222222;
$color_link: #222222;
$color_heading: #222222;

$color_bg: #c4c6c6;

$font_feature_settings_palt: false;
$font_smooth: true;

$blue: #013879;
$baby_blue: #0394D9;
$light_blue: #C0E7FD;
$red: #ED1B35;
$border: #E6E6E6;
$border_light: #D6D6D6;
$gray: #8B8B8B;
$placeholder_gray: #B6B6B6;

$browser_prefix: ("-webkit-", "-moz-", "-ms-", "-o-", "");
@mixin add_prefix($key, $value) {
  @each $prefix in $browser_prefix {
    #{$prefix}$key: $value;
  }
}


/* font style */
@mixin bai{
  font-family:  'Bai Jamjuree', sans-serif;
}

@mixin font-smooth() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin off-font-smooth() {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@mixin palt() {
  @include add_prefix(font-feature-settings, "palt");
}
@mixin thumb{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include add_prefix(backface-visibility,hidden);
  @include transition_c(all 0.4s);
}
/* site style */
@mixin text-hide {
  overflow: hidden;
  text-indent: -9999px;
}

/* [ writing-mode ]
-------------------------------------------------*/
@mixin writing-mode($orientation: "h", $direction: "rl") {
  @if $orientation == "v" {
    // Vertical with direction, rl by default
    @include add_prefix(writing-mode, vertical- + $direction);
  } @else {
    // Default...
    @include add_prefix(writing-mode, horizontal-tb);
  }
}

/* [ easy breakpoint ]
-------------------------------------------------*/
@mixin PC {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin SP {
  @media (max-width: 767px) {
    @content;
  }
}

//aspect-ratio fixed box
//==================================================
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// CLEARFIX
@mixin clearfix() {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

//==================================================
// Triangle helper mixin
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color
// @param {Length} $size [1em] - Triangle size
// Call | .css::before { @include triangle(bottom, #000, 5px); }
//==================================================
@mixin triangle($direction, $color: currentcolor, $size: 1em, $distance: 5px) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  margin: $distance;
  border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

// SINGLE LINE ELLIPSIS
//==================================================
@mixin singleLineEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// MULTIPLE LINES ELLIPSIS
//==================================================
@mixin multiLineEllipsis($lineCount: 1) {
  overflow: hidden;
  width: 100%;
  -webkit-line-clamp: $lineCount;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: normal;
}

// FLEXBOX
//==================================================
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($value: 1) {
  @include add_prefix(flex, $value);
}

@mixin flex-wrap($wrap: wrap) {
  @include add_prefix(flex-wrap, $wrap);
}

@mixin align-items($align: center) {
  @include add_prefix(align-items, $align);
}

@mixin align-content($align: space-between) {
  @include add_prefix(align-content, $align);
}

@mixin justify-content($direction: center) {
  @include add_prefix(justify-content, $direction);
}

@mixin flex-direction($direction: column) {
  @include add_prefix(flex-direction, $direction);
}

// ANIMATION KEY-FRAME
//==================================================
@mixin keyframes($content) {
  @-webkit-keyframes #{$content} {
    @content;
  }
  @keyframes #{$content} {
    @content;
  }
}
@mixin transition_c ($content, $opt: "") {
  @each $prefix in $browser_prefix {
    @if ($opt == "") {
      #{$prefix}transition: $content;
    } @else {
      #{$prefix}transition-#{$opt}: $content;
    }
  }
}

@mixin transform_c ($content) {
  @each $prefix in $browser_prefix {
    #{$prefix}transform: $content;
  }
}
// FONT
//==================================================
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
@mixin font($size: "", $line: "", $letter: "", $weight: "") {
  @if $size != "" {
    $size-no-unit: strip-unit($size);
    font-size: $size-no-unit + px;
    font-size: ($size-no-unit) / 10 + rem;
  }
  @if $line != "" {
    $line-no-unit: strip-unit($line);
    line-height: $line-no-unit;
  }
  @if $letter != "" {
    $letter-no-unit: strip-unit($letter);
    letter-spacing: $letter-no-unit + em;
  }
  @if $weight != "" {
    font-weight: $weight;
  }
}
