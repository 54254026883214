@charset 'UTF-8';
@import "/Users/minhminh/Website/vhost/lua-viet-corp/src/scss/utility/_media-queries.scss";
@import "/Users/minhminh/Website/vhost/lua-viet-corp/src/scss/utility/_mixin.scss";


.cont-404{
  text-align: center;
  padding: 100px 0 150px;
  .txt{
    @include font(16,24/16,0,500);
    text-align: center;
    margin: 30px auto 40px;
  }
  .btnBorder{margin: 0 auto;}
}
